/**
 * @generated SignedSource<<a1533bfab397dac42e02bc760847af6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasManual",
  "storageKey": null
},
v2 = {
  "kind": "Literal",
  "name": "includeParentOrganisation",
  "value": true
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  (v0/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "currentOrganisation",
      "value": true
    },
    (v2/*: any*/)
  ],
  "concreteType": "JobTitleConnection",
  "kind": "LinkedField",
  "name": "jobTitles",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "JobTitle",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": (v5/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": "jobTitles(currentOrganisation:true,includeParentOrganisation:true)"
},
v7 = {
  "alias": null,
  "args": [
    (v2/*: any*/),
    {
      "kind": "Literal",
      "name": "roles",
      "value": [
        "admin",
        "editor",
        "author"
      ]
    },
    {
      "kind": "Literal",
      "name": "sortBy",
      "value": "name"
    },
    {
      "kind": "Literal",
      "name": "sortDirection",
      "value": "asc"
    }
  ],
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "users",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": "users(includeParentOrganisation:true,roles:[\"admin\",\"editor\",\"author\"],sortBy:\"name\",sortDirection:\"asc\")"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasCia",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasQt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasRisk",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "confidentiality",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "integrity",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "NormParagraph",
  "kind": "LinkedField",
  "name": "normParagraphs",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewSupplierQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v6/*: any*/),
      (v7/*: any*/),
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "SupplierFormFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NewSupplierQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v3/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasRtoRpo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RiskAttitude",
            "kind": "LinkedField",
            "name": "riskAttitude",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "criticalValue",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maxRange",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v6/*: any*/),
      (v7/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "normRequirementType",
            "value": "supplier"
          }
        ],
        "concreteType": "NormRequirement",
        "kind": "LinkedField",
        "name": "normRequirements",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v4/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "normParagraphIds",
            "storageKey": null
          },
          (v3/*: any*/),
          (v13/*: any*/)
        ],
        "storageKey": "normRequirements(normRequirementType:\"supplier\")"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyName",
            "storageKey": null
          },
          (v0/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasLabels",
            "storageKey": null
          },
          (v9/*: any*/),
          (v10/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ResourceConnection",
        "kind": "LinkedField",
        "name": "resources",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Resource",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v4/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "availability",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Norm",
        "kind": "LinkedField",
        "name": "norms",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v4/*: any*/),
          (v13/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1cb2714106af817a26046e1eab1b1475",
    "id": null,
    "metadata": {},
    "name": "NewSupplierQuery",
    "operationKind": "query",
    "text": "query NewSupplierQuery {\n  currentOrganisation {\n    databaseId\n    hasManual\n    id\n  }\n  jobTitles(currentOrganisation: true, includeParentOrganisation: true) {\n    nodes {\n      __typename\n      databaseId\n      id\n      name\n    }\n  }\n  users(includeParentOrganisation: true, roles: [\"admin\", \"editor\", \"author\"], sortBy: \"name\", sortDirection: \"asc\") {\n    __typename\n    databaseId\n    id\n    name\n  }\n  ...SupplierFormFragment_query\n}\n\nfragment CiaForm_query on Query {\n  currentOrganisation {\n    hasCia\n    hasQt\n    hasRisk\n    hasRtoRpo\n    riskAttitude {\n      criticalValue\n      maxRange\n      id\n    }\n    id\n  }\n}\n\nfragment ConfirmTakeNormParagraphs_query_2pouPp on Query {\n  normRequirements(normRequirementType: \"supplier\") {\n    databaseId\n    name\n    normParagraphIds\n    normParagraphs {\n      databaseId\n      name\n      number\n      id\n    }\n    id\n  }\n}\n\nfragment NormSelector_query on Query {\n  norms {\n    databaseId\n    name\n    normParagraphs {\n      databaseId\n      name\n      number\n      id\n    }\n    id\n  }\n}\n\nfragment SupplierFormFragment_query on Query {\n  normRequirements(normRequirementType: \"supplier\") {\n    databaseId\n    name\n    confidentiality\n    integrity\n    normParagraphIds\n    id\n  }\n  organisations {\n    companyName\n    databaseId\n    hasCia\n    hasLabels\n    hasQt\n    hasRisk\n    id\n  }\n  resources {\n    nodes {\n      databaseId\n      name\n      confidentiality\n      integrity\n      availability\n      id\n    }\n  }\n  ...CiaForm_query\n  ...ConfirmTakeNormParagraphs_query_2pouPp\n  ...NormSelector_query\n}\n"
  }
};
})();

node.hash = "c744b6a42302b3b22f8a3e84d97e53b8";

module.exports = node;
