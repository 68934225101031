/**
 * @generated SignedSource<<6c9ec0cca984c663a3fe629611a2c345>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasDoa",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "normOrganisationId",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NormOrganisationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NormOrganisation",
        "kind": "LinkedField",
        "name": "normOrganisation",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NormParagraph_normOrganisation"
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Norm",
            "kind": "LinkedField",
            "name": "norm",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NormParagraph",
            "kind": "LinkedField",
            "name": "normParagraphs",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NormParagraph_normParagraph"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NormOrganisationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NormOrganisation",
        "kind": "LinkedField",
        "name": "normOrganisation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Norm",
            "kind": "LinkedField",
            "name": "norm",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "NormColumn",
                "kind": "LinkedField",
                "name": "normColumns",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "columnType",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organisation",
            "kind": "LinkedField",
            "name": "organisation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ManualChapter",
                "kind": "LinkedField",
                "name": "manualChapters",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ManualParagraph",
                    "kind": "LinkedField",
                    "name": "manualParagraphs",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Unit",
                "kind": "LinkedField",
                "name": "units",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NormParagraph",
            "kind": "LinkedField",
            "name": "normParagraphs",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v8/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "explanation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isControlMeasure",
                "storageKey": null
              },
              (v4/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v9/*: any*/),
                "concreteType": "NormExplanation",
                "kind": "LinkedField",
                "name": "normExplanation",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isApplicable",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isImplemented",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reasonBestPractices",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reasonContract",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reasonLaw",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reasonRisk",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v9/*: any*/),
                "concreteType": "NormLinkOrganisation",
                "kind": "LinkedField",
                "name": "normLinkOrganisations",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ManualParagraph",
                    "kind": "LinkedField",
                    "name": "manualParagraph",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v6/*: any*/),
                      (v4/*: any*/),
                      (v7/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Deviation",
                "kind": "LinkedField",
                "name": "deviations",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v9/*: any*/),
                "concreteType": "NormColumnAnswer",
                "kind": "LinkedField",
                "name": "normColumnAnswers",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "answer",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "normColumnId",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c216f40b21b9f3651deabf66cc2f8709",
    "id": null,
    "metadata": {},
    "name": "NormOrganisationQuery",
    "operationKind": "query",
    "text": "query NormOrganisationQuery(\n  $id: ID!\n) {\n  normOrganisation(id: $id) {\n    ...NormParagraph_normOrganisation\n    databaseId\n    norm {\n      hasDoa\n      name\n      id\n    }\n    normParagraphs {\n      id\n      ...NormParagraph_normParagraph\n    }\n    id\n  }\n}\n\nfragment EditNormParagraph_normOrganisation on NormOrganisation {\n  databaseId\n  id\n  norm {\n    hasDoa\n    normColumns {\n      databaseId\n      id\n      columnType\n      name\n    }\n    id\n  }\n  organisation {\n    databaseId\n    id\n  }\n}\n\nfragment EditNormParagraph_normParagraph on NormParagraph {\n  databaseId\n  id\n  deviations {\n    databaseId\n    name\n    id\n  }\n  normColumnAnswers(normOrganisationId: $id) {\n    databaseId\n    answer\n    normColumnId\n    id\n  }\n}\n\nfragment EditReasonLink_normOrganisation on NormOrganisation {\n  organisation {\n    units {\n      name\n      id\n    }\n    id\n  }\n}\n\nfragment NormParagraph_normOrganisation on NormOrganisation {\n  databaseId\n  id\n  norm {\n    hasDoa\n    id\n  }\n  organisation {\n    manualChapters {\n      databaseId\n      manualParagraphs {\n        databaseId\n        name\n        number\n        status\n        id\n      }\n      id\n    }\n    id\n  }\n  ...EditNormParagraph_normOrganisation\n  ...EditReasonLink_normOrganisation\n}\n\nfragment NormParagraph_normParagraph on NormParagraph {\n  content\n  databaseId\n  explanation\n  isControlMeasure\n  name\n  number\n  normExplanation(normOrganisationId: $id) {\n    content\n    databaseId\n    isApplicable\n    isImplemented\n    reasonBestPractices\n    reasonContract\n    reasonLaw\n    reasonRisk\n    id\n  }\n  normLinkOrganisations(normOrganisationId: $id) {\n    databaseId\n    manualParagraph {\n      databaseId\n      number\n      name\n      status\n      id\n    }\n    id\n  }\n  ...EditNormParagraph_normParagraph\n}\n"
  }
};
})();

node.hash = "26b73f7849e5a79df5e9c596cec14f9e";

module.exports = node;
