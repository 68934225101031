/**
 * @generated SignedSource<<bd911ef3767224f75aedd86e9373aeeb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "id"
    }
  ],
  "concreteType": "Audit",
  "kind": "LinkedField",
  "name": "audit",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organisationId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasAudit",
  "storageKey": null
},
v6 = {
  "kind": "Literal",
  "name": "sortBy",
  "value": "name"
},
v7 = {
  "kind": "Literal",
  "name": "sortDirection",
  "value": "asc"
},
v8 = {
  "kind": "Literal",
  "name": "statuses",
  "value": [
    "active",
    "pending"
  ]
},
v9 = [
  {
    "kind": "Literal",
    "name": "roles",
    "value": [
      "admin",
      "editor"
    ]
  },
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/)
],
v10 = {
  "kind": "Variable",
  "name": "auditId",
  "variableName": "id"
},
v11 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuditAgendaInviteQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "IntervieweeFilter_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "IntervieweeFilterRefetch_query"
      },
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "users",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": "users(roles:[\"admin\",\"editor\"],sortBy:\"name\",sortDirection:\"asc\",statuses:[\"active\",\"pending\"])"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuditAgendaInviteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parentOrganisationId",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "interviewees",
        "args": [
          (v10/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/)
        ],
        "concreteType": "MemberConnection",
        "kind": "LinkedField",
        "name": "members",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Member",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v10/*: any*/)
                ],
                "concreteType": "JobTitle",
                "kind": "LinkedField",
                "name": "auditJobTitles",
                "plural": true,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "currentOrganisation",
            "value": true
          }
        ],
        "concreteType": "JobTitleConnection",
        "kind": "LinkedField",
        "name": "jobTitles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JobTitle",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": (v11/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": "jobTitles(currentOrganisation:true)"
      },
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "users",
        "plural": true,
        "selections": (v11/*: any*/),
        "storageKey": "users(roles:[\"admin\",\"editor\"],sortBy:\"name\",sortDirection:\"asc\",statuses:[\"active\",\"pending\"])"
      }
    ]
  },
  "params": {
    "cacheID": "4549839867175d8190eef404a420a5fe",
    "id": null,
    "metadata": {},
    "name": "AuditAgendaInviteQuery",
    "operationKind": "query",
    "text": "query AuditAgendaInviteQuery(\n  $id: ID!\n) {\n  ...IntervieweeFilter_query\n  ...IntervieweeFilterRefetch_query\n  audit(id: $id) {\n    id\n    databaseId\n    name\n    organisationId\n  }\n  currentOrganisation {\n    hasAudit\n    id\n  }\n  currentUser {\n    databaseId\n    id\n  }\n  users(roles: [\"admin\", \"editor\"], sortBy: \"name\", sortDirection: \"asc\", statuses: [\"active\", \"pending\"]) {\n    databaseId\n    name\n    id\n  }\n}\n\nfragment IntervieweeFilterRefetch_query on Query {\n  interviewees: members(auditId: $id, sortBy: \"name\", sortDirection: \"asc\", statuses: [\"active\", \"pending\"]) {\n    nodes {\n      auditJobTitles(auditId: $id) {\n        databaseId\n        name\n        id\n      }\n      user {\n        databaseId\n        name\n        id\n      }\n      id\n    }\n  }\n  jobTitles(currentOrganisation: true) {\n    nodes {\n      databaseId\n      name\n      id\n    }\n  }\n}\n\nfragment IntervieweeFilter_query on Query {\n  organisations {\n    databaseId\n    companyName\n    parentOrganisationId\n    id\n  }\n}\n"
  }
};
})();

node.hash = "0b6eec6532f0a558bd070c8114a5c867";

module.exports = node;
